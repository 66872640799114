import React from "react";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languageButtonStyle = (languageCode) => {
    return `ml-3 text-white hover:text-gray-500 focus:outline-none rounded-full px-3 py-1 ${
      i18n.language === languageCode
        ? "bg-gray-600 shadow-md"
        : "bg-transparent"
    }`;
  };

  return (
    <header className="bg-gray-800 top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          href="#about"
          className="title-font font-medium text-white mb-4 md:mb-0"
        >
          Eduardo Hernandez
        </a>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center text-base justify-center">
          <a href="#projects" className="mr-5 hover:text-white">
            {t("navbar.pastWork")}
          </a>
          <a href="#skills" className="mr-5 hover:text-white">
            {t("navbar.skills")}
          </a>
          <a href="#testimonials" className="mr-5 hover:text-white">
            {t("navbar.testimonials")}
          </a>
        </nav>
        {/* Contenedor para los botones de idioma */}
        <div className="flex items-center pr-2">
          <button
            onClick={() => changeLanguage("en")}
            className={languageButtonStyle("en")}
          >
            EN
          </button>
          <button
            onClick={() => changeLanguage("es")}
            className={languageButtonStyle("es")}
          >
            ES
          </button>
        </div>
        <a
          href="#contact"
          className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
        >
          {t("navbar.hireMe")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </a>
      </div>
    </header>
  );
}
