import React from "react";
import { useTranslation } from "react-i18next";

export default function Projects() {
  const { t } = useTranslation();
  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mx-auto inline-block w-10 mb-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
            />
          </svg>
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            {t("projects.title")}
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            {t("projects.subtitle")}
            <a className="underline" href="https://github.com/EduHz">
              GitHub
            </a>
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {/* Notas App */}
          <a
            href="https://notes.eduhzclick.com"
            className="sm:w-1/2 w-100 p-4"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto5.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                  PHP
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  Notes App
                </h1>
                <p className="leading-relaxed">{t("projects.description5")}</p>
              </div>
            </div>
          </a>
          <a
            href="https://besthungrygator.com/"
            className="sm:w-1/2 w-100 p-4"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto2.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-orange-600 mb-1">
                  Responsive Site
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  BestHungryGator
                </h1>
                <p className="leading-relaxed">{t("projects.description2")}</p>
              </div>
            </div>
          </a>
          <a
            href="https://drivedu.vercel.app/" // Comentario para pushear
            className="sm:w-1/2 w-100 p-4"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto1.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-blue-400 mb-1">
                  Full Stack Adventures
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  Drivedu
                </h1>
                <p className="leading-relaxed">{t("projects.description1")}</p>
              </div>
            </div>
          </a>
          <a
            href="https://www.besthomeresourcesllc.com"
            rel="noreferrer"
            target="_blank"
            className="sm:w-1/2 w-100 p-4"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto3.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-pink-400 mb-1">
                  Creative Coding Creations
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  Home Resources LLC
                </h1>
                <p className="leading-relaxed">{t("projects.description3")}</p>
              </div>
            </div>
          </a>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/fir-frontend-31d44.appspot.com/o/archivosIdeo%2FDise%C3%B1o%20sin%20t%C3%ADtulo%20(1).png?alt=media&token=17aa3585-1cf3-4653-8b2a-d0fd506e24fb"
            className="sm:w-1/2 w-100 p-4"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto4.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-lime-400 mb-1">
                  Phone App
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  ComarcApp
                </h1>
                <p className="leading-relaxed">{t("projects.description4")}</p>
              </div>
            </div>
          </a>

          {/* Notas App 2*/}
          <a
            href="https://weather.eduhzclick.com/"
            className="sm:w-1/2 w-100 p-4"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex relative">
              <img
                alt="gallery"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src="./proyecto6.jpg"
              />
              <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                <h2 className="tracking-widest text-sm title-font font-medium text-yellow-200 mb-1">
                  React Api
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  Weather Map
                </h1>
                <p className="leading-relaxed">{t("projects.description6")}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}


