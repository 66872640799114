import React from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";

export default function Contact() {
  const { t } = useTranslation();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zfeoiyo",
        "template_q58m6g2",
        e.target,
        "PSNE65L-nviRTqkUD"
      )
      .then(
        (result) => {
          console.log(result.text);
          showAlert(t("alerta"));
        },
        (error) => {
          console.log(error.text);
          showAlert(t("alerta.error"));
        }
      );

    e.target.reset();
  }

  const showAlert = (message) => {
    const alertDiv = document.createElement("div");
    alertDiv.className =
      "fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50";
    const alertContent = document.createElement("div");
    alertContent.className =
      "bg-white rounded-lg p-8 max-w-md w-full mx-4 text-center";
    const alertMessage = document.createElement("p");
    alertMessage.className = "text-gray-900 italic";
    alertMessage.textContent = message;
    alertContent.appendChild(alertMessage);
    alertDiv.appendChild(alertContent);
    document.body.appendChild(alertDiv);

    setTimeout(() => {
      alertDiv.remove();
    }, 5000); // Remove alert after 5 seconds
  };

  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative hidden md:flex">
          <iframe
            width="100%"
            height="100%"
            title="map"
            className="absolute inset-0"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            style={{ filter: "opacity(0.8)" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47465.79493436994!2d-71.5651459849718!3d-41.96566436471467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x961bbe292d49ca89%3A0xa4fcd4026336e0f6!2zRWwgQm9sc8OzbiwgUsOtbyBOZWdybw!5e0!3m2!1ses!2sar!4v1712678871574!5m2!1ses!2sar"
          />
          <div className="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md w-full">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-white tracking-widest text-xs">
                {t("formulario.address")}
              </h2>
              <p className="mt-1">
                El Bolsón, Río Negro <br />
                R8430XAA, Argentina
              </p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-white tracking-widest text-xs">
                {t("formulario.email")}
              </h2>
              <a
                className="text-indigo-400 leading-relaxed"
                href="mailto:eduhzprogramador@gmail.com"
              >
                eduhzprogramador@gmail.com
              </a>
            </div>
          </div>
        </div>
        <form
          onSubmit={sendEmail}
          className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
        >
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            {t("formulario.contact")}
          </h2>
          <p className="leading-relaxed mb-5">{t("formulario.contactme")}</p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              {t("formulario.name")}
            </label>
            <input
              type="text"
              id="name"
              name="from_name"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              {t("formulario.email2")}
            </label>
            <input
              type="email"
              id="email"
              name="from_email"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400"
            >
              {t("formulario.message")}
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            type="submit"
            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            {t("formulario.button")}
          </button>
        </form>
      </div>
    </section>
  );
}
