import About from "./components/About";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

export default function App() {
  return (
    <div className="text-gray-200 bg-gray-900 body-font">
      <Navbar />
      <About />
      <Projects />
      <Skills />
      <Clients />
      <Contact />
    </div>
  );
}

// Pusheando desde w11 - vs code
 
