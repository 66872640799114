export const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "PHP",
  "SQL",
  "Node JS",
  "React",
  "React Native",
];
